<!--
 * @Author: 月魂
 * @Date: 2021-03-25 15:15:05
 * @LastEditTime: 2021-04-29 14:42:36
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \stoms-mp\src\views\CardOrderDetail.vue
-->
<template>
  <div class="box">
    <van-nav-bar
      title="订单详情"
      fixed
      placeholder
    >
      <template #right>
        <van-icon name="wap-home-o" size="18" @click="jumpHome" />
      </template>
    </van-nav-bar>
    <!-- <div class="status">
      <div class="left">
        <div class="top">
          <div class="title">{{statusMap[data.status]}}</div>
        </div>
        <div class="bottom" v-if="data.status === 0">
          <div class="tips">活动即将结束，请您尽快支付!</div>
        </div>
      </div>
      <div class="right" v-if="data.status === 1">
        <img src="../assets/code.svg" @click="show = true">
      </div>
    </div> -->
    <div v-for="item in data.details" :key="item.id">
      <div class="card">
       <img :src="item.good_image_url" class="cardImg">
      </div>
      <div class="content">
        <div class="item">
          <van-row>
            <van-col span="6">商品类型</van-col>
            <van-col span="18">{{ item.good_name }}</van-col>
          </van-row>
          <van-row>
            <van-col span="6">商品数量</van-col>
            <van-col span="18">{{ item.quantity }}</van-col>
          </van-row>
          <van-row>
            <van-col span="6">商品金额</van-col>
            <van-col span="18"><span style="color: #D9001B">￥{{ item.selling_price }}</span></van-col>
          </van-row>
          <van-row v-if="item.good_type == 1">
            <van-col span="6">总次数</van-col>
            <van-col span="18">{{ item.good_total_times }} 次</van-col>
          </van-row>
          <van-row v-else>
            <van-col span="6">有效期</van-col>
            <van-col span="18">{{ item.good_period }} 天</van-col>
          </van-row>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="item">
        <van-row>
          <van-col span="6">订单编号</van-col>
          <van-col span="18"><span style="color: #7f7f7f; font-size: 12px;">{{ data.number }}</span></van-col>
        </van-row>
        <van-row>
          <van-col span="6">订单金额</van-col>
          <van-col span="18"><span style="color: #D9001B">￥{{ data.price }}</span></van-col>
        </van-row>
        <van-row>
          <van-col span="6">订单状态</van-col>
          <van-col span="18"><span style="color: #D9001B">{{ statusMap[data.status] }}</span></van-col>
        </van-row>
        <!-- <van-row>
          <van-col span="6">数量</van-col>
          <van-col span="18"><span>{{data.quantity}}</span></van-col>
        </van-row> -->
        <van-row>
          <van-col span="6">创建时间</van-col>
          <van-col span="18"><span style="color: #7f7f7f; font-size: 12px;">{{ data.create_time }}</span></van-col>
        </van-row>
      </div>
    </div>
    <van-goods-action safe-area-inset-bottom v-if="data.status === 0">
      <van-goods-action-button
        type="warning"
        color="#d1d1d1"
        text="取消订单"
        @click="handleCancel(data.id)"
      />
       <van-goods-action-button
        type="danger"
        text="立即购买"
        @click="handleBuy(data.id)"
      />
    </van-goods-action>
    <van-action-sheet v-model="show" title="订单二维码">
      <div class="code">
        <div class="number">订单编号： {{data.number}}</div>
        <img src="../assets/code.svg">
        <div class="msg">可凭此二维码取票入场</div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import Vue from 'vue';
import { getCardOrderDetail, cancelOrder } from '@/api/order'
import { NavBar, Row, Col, GoodsAction, GoodsActionButton, ActionSheet, Toast, Icon } from 'vant'
Vue.use(NavBar).use(Row).use(Col).use(GoodsAction).use(GoodsActionButton).use(ActionSheet).use(Toast).use(Icon)
export default {
  name: 'CardOrderDetail',
  data() {
    return {
      show: false,
      data: {},
      statusMap: {
        0: '待支付',
        1: '已支付',
        '-1': '已取消',
        '-2': '已取消'
      }
    }
  },
  mounted() {
    getCardOrderDetail(this.$route.params.id).then(res => {
      if (res.code !== 0) return
      this.data = res.data
    })
  },
  methods: {
    jumpHome() {
      this.$router.replace('/')
    },
    handleCancel(id) {
      cancelOrder(id).then(res => {
        if (res.code !== 0) return
        this.data.status = -1
        Toast.success('取消成功');
      })
    },
    handleBuy(id) {
      this.$router.push(`/pay/${id}`)
    }
  },
}
</script>

<style lang="less" scoped>
.box {
  min-height: 100%;
  background-color: #f9f9f9;
  .status {
    display: flex;
    justify-content: space-between;
    background-color: #DD2F45;
    padding: 10px;
    .left {
      .top {
        display: flex;
        align-items: flex-end;
        padding: 10px 0;
        .title {
          font-size: 36px;
          color: #fff;
          margin-right: 10px;
        }
        .time {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.8);
        }
      }
      .bottom {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.8);
        padding-bottom: 10px;
      }
    }
    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
      }
    }
  }
  .card {
    box-sizing: border-box;
    width: 355px;
    border-radius: 20px;
    overflow: hidden;
    margin: 10px auto;
    .cardImg {
      display: block;
      width: 100%;
      height: 150px;
    }
  }
  .content {
    background-color: #fff;
    padding: 10px;
    font-size: 14px;
    color: #555;
    line-height: 2;
    margin-bottom: 10px;
  }
  .code {
    padding: 10px 10px 30px;
    text-align: center;
    .number {
      font-size: 14px;
      color: #555;
      line-height: 2;
    }
    img {
      width: 30%;
      margin: 30px auto;
    }
    .msg {
      font-size: 14px;
      color: #555;
      line-height: 2;
    }
  }
}
</style>
